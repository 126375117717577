import {
  email,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import { isCustomField } from "./fields";

const getValidatorsParams = (vParams) => {
  const validators = {};

  for (let v in vParams) {
    if (v === "required" && vParams[v]) {
      validators[v] = required;
    } else if (v === "max_length") {
      validators["maxLength"] = maxLength(vParams[v]);
    } else if (v === "min_length") {
      validators["minLength"] = minLength(vParams[v]);
    } else if (v === "email") {
      validators["email"] = email;
    }
  }
  return validators;
};

export const getValidation = (validators) => {
  const _validators = {};
  if (!validators) return _validators;

  for (let fieldName in validators) {
    const value = getValidatorsParams(validators[fieldName]);

    if (!isCustomField(fieldName) || !fieldName.includes(".")) {
      _validators[fieldName] = value;
    } else {
      const dotIndex = fieldName.indexOf(".");
      const key = fieldName.substring(0, dotIndex);
      const subKey = fieldName.substring(dotIndex + 1);
      if (key in _validators) {
        _validators[key][subKey] = value;
      } else {
        _validators[key] = { [subKey]: value };
      }
    }
  }

  return _validators;
};
