import TreeService from "../treeService";
import {
  CUSTOM_FIELDS_PREFIX,
  getFieldOptions,
  getFieldOptionText,
  getFieldOptionValue,
  getFieldType,
  getFormDataStructure,
  getPlaceholder,
  getTitle,
  GRID_COUNT,
  isCustomField,
  isFieldReadonly,
  isFieldVisible,
  SYSTEM_OBJECT_FIELDS,
} from "./fields";
import { getValidation } from "./formValidation";

const formService = {
  /**
   * @param {object} data сырые данные полученные с сервера
   * @param {array} fields поля формы
   * @return {object} возвращает formData с заполненными данными
   */
  deserialize({ data, fields, defaultValues = {} }) {
    const formDataStructure = getFormDataStructure(fields);

    const result = {};
    for (let field in formDataStructure) {
      if (defaultValues && field in defaultValues && defaultValues[field]) {
        result[field] = defaultValues[field];
      } else if (!data) {
        result[field] = formDataStructure[field];
      } else if (SYSTEM_OBJECT_FIELDS.includes(field)) {
        result[field] = data[field]?.id || null;
      } else if (!isCustomField(field)) {
        result[field] = data[field];
      } else if (typeof formDataStructure[field] === "string") {
        result[field] = data[CUSTOM_FIELDS_PREFIX][field] || "";
      } else {
        const cf = {};
        for (let subField in formDataStructure[field]) {
          cf[subField] = TreeService.treeGetDictFlatValue(
            data[CUSTOM_FIELDS_PREFIX][field],
            subField
          );
        }
        result[field] = cf;
      }
    }

    return result;
  },

  /**
   * @param {object} data заполненные данные формы
   * @return {object} data возвращает данные для отправки на сервер
   */
  serialize(data) {
    const result = {};
    const customFields = {};

    for (let fieldName in data) {
      if (SYSTEM_OBJECT_FIELDS.includes(fieldName)) {
        result[fieldName + "_id"] = data[fieldName];
      } else if (isCustomField(fieldName)) {
        const value =
          typeof data[fieldName] === "string"
            ? data[fieldName]
            : TreeService.treeFromDictFlatToDict(data[fieldName]);

        customFields[fieldName] = value;
      } else {
        result[fieldName] = data[fieldName];
      }
    }

    result[CUSTOM_FIELDS_PREFIX] = customFields;

    return result;
  },

  /**
   * @param {object} form форма
   * @param {array} fields поля приложения
   * @param {array} statuses статусы приложения
   * @param {array} users пользователи приложения
   * @param {array} editableFields права на редактирование полей
   * @param {array} visibleFields права на просмотр полей
   * @param {boolean} isFormReadonly право на редактирование всех полей формы
   * @param {function} toLocaleMethod метод для локализации полей
   * @return {object} возвращает конфиг полей для работы формы
   */
  getFieldSettings({
    form,
    fields,
    statuses,
    users,
    editableFields,
    visibleFields,
    isFormReadonly,
    toLocaleMethod,
  }) {
    const display = form.display;
    const text = display?.text?.fields || {};
    const grid = display.grid || {};
    const icons = display.icons || {};
    const result = {};
    const displayFields = display.fields;

    fields.forEach((field) => {
      result[field.name] = {
        type: getFieldType({ field, form }),
        title: getTitle({ text, field, toLocaleMethod }),
        placeholder: getPlaceholder({ field, toLocaleMethod }),
        icon: icons[field.name] || null,
        grid: grid[field.name] || GRID_COUNT,
        options: getFieldOptions({ users, statuses, field }),
        optionText: getFieldOptionText({ field }),
        optionValue: getFieldOptionValue({ field }),
        default: field.default || null,
        readonly: isFieldReadonly({
          isFormReadonly,
          fieldName: field.name,
          editableFields,
        }),
        visible: isFieldVisible({
          fieldName: field.name,
          visibleFields,
          displayFields,
        }),
        children: null,
      };

      if (field.structure) {
        result[field.name].children = {};

        for (let subFieldName in field.structure) {
          const subFieldWithParentName = `${field.name}.${subFieldName}`;
          const subField = field.structure[subFieldName];

          result[field.name].children[subFieldName] = {
            type: subField.type,
            title: text[subFieldWithParentName] || subFieldName,
            placeholder: text[subFieldWithParentName] || subFieldName,
            icon: icons[subFieldWithParentName] || null,
            grid: grid[subFieldWithParentName] || GRID_COUNT,
            options: subField.options || null,
            optionText: subField.options ? "title" : null,
            optionValue: subField.options ? "value" : null,
            default: subField.default || null,
            readonly: isFieldReadonly({
              isFormReadonly,
              fieldName: subFieldWithParentName,
              editableFields,
            }),
            visible: isFieldVisible({
              fieldName: field.name,
              visibleFields,
            }),
            children: null,
          };
        }
      }
    });

    return result;
  },

  getValidation,
};

export { formService };
