<template>
  <v-form ref="form">
    <Grids
      v-if="layout.length"
      :layout="layout"
      :isDraggable="widgetsIsDraggable"
    >
      <template #default="{ index }">
        <WidgetWrapper
          :pluginConfigId="getPluginConfigId(widgets[index])"
          :nodeId="node.id"
        >
          <template v-for="(fieldName, k) in widgets[index].fields">
            <h4
              v-show="fieldSettings[fieldName].visible"
              :key="'h4' + k"
              class="mb-2"
            >
              {{ fieldSettings[fieldName].title }}
            </h4>
            <v-row
              v-show="fieldSettings[fieldName].visible"
              :key="'row' + k"
              no-gutters
              class="mb-4"
            >
              <v-col cols="12" sm="12" :md="fieldSettings[fieldName].grid">
                <AppFieldCreator
                  v-model.trim="formData[fieldName]"
                  :label="fieldSettings[fieldName].title"
                  :placeholder="fieldSettings[fieldName].placeholder"
                  :items="fieldSettings[fieldName].options"
                  :type="fieldSettings[fieldName].type"
                  :itemText="fieldSettings[fieldName].optionText"
                  :itemValue="fieldSettings[fieldName].optionValue"
                  :prependIcon="fieldSettings[fieldName].icon"
                  :fieldName="fieldName"
                  :fieldSettings="fieldSettings[fieldName].children"
                  :v="$v.formData[fieldName]"
                  :readonly="fieldSettings[fieldName].readonly"
                  class="px-2 mb-2"
                  hide-details="auto"
                  dense
                  @input="$v.$touch()"
                  @blur="$emit('blur', $event)"
                  @change="onChange"
                  @onAttachmentDelete="$emit('onAttachmentDelete', $event)"
                  @onAttachmentAppend="$emit('onAttachmentAppend', $event)"
                />
              </v-col>
            </v-row>
          </template>
        </WidgetWrapper>
      </template>
    </Grids>
  </v-form>
</template>

<script>
import { mapState } from "vuex";

import formMixin from "@/components/Form/mixins/formMixin";
import Grids from "@/components/Grids/Grids.vue";
import WidgetWrapper from "@/components/widgets/WidgetWrapper/WidgetWrapper.vue";

export default {
  mixins: [formMixin],
  components: { Grids, WidgetWrapper },
  props: {
    layout: {
      // для типа формы - board
      type: Array,
      default: () => [],
    },
    widgets: {
      // для типа формы - board
      type: Array,
      default: () => [],
    },
    saveOnChange: {
      type: Boolean,
      default: true,
    },
    widgetsIsDraggable: {
      // для типа формы - board
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("core$common", ["node"]),
  },
  methods: {
    getPluginConfigId(widget) {
      if (!widget.plugin_config_id) return "";
      return widget.plugin_config_id;
    },
    onChange(e) {
      this.$emit("change", e);
      if (this.saveOnChange) this.$emit("onSave");
    },
  },
};
</script>
