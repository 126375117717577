export default {
  props: {
    title: {
      // Заголовок формы
      type: String,
      default: "",
    },
    btnSubmitText: {
      // текст кнопки
      type: String,
      default: "submit",
    },
    data: {
      // Реактивные данные полей
      type: Object,
      required: true,
    },
    fieldSettings: {
      // описание полей
      type: Object,
      required: true,
    },
    validation: {
      // Валидация полей
      type: Object,
      default: () => ({}),
    },
    tabs: {
      // для типа формы - tabs
      type: Array,
      default: () => [],
    },
    fields: {
      // для типа формы - simple
      type: Array,
      default: () => [],
    },
    agreementLink: {
      // ссылка на пользовательское соглашение
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    formIsInvalid() {
      return this.$v.$invalid;
    },
  },
  validations() {
    return { formData: this.validation };
  },
  methods: {
    createFormData() {
      for (let fieldName in this.data) {
        const fieldValue = this.data[fieldName];

        if (typeof fieldValue === "string" || !fieldName.startsWith("cf_")) {
          this.$set(this.formData, fieldName, fieldValue);
          continue;
        }

        this.$set(this.formData, fieldName, {});
        for (let subField in fieldValue) {
          this.$set(this.formData[fieldName], subField, fieldValue[subField]);
        }
      }
    },
    updateFormData() {
      for (let fieldName in this.data) {
        const fieldValue = this.data[fieldName];

        if (typeof fieldValue === "string" || !fieldName.startsWith("cf_")) {
          this.formData[fieldName] = fieldValue;
          continue;
        }

        for (let subField in fieldValue) {
          this.formData[fieldName][subField] = fieldValue[subField];
        }
      }
    },
  },
  created() {
    this.createFormData();
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.updateFormData();
      },
    },
  },
};
