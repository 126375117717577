<template>
  <div>
    <h3 v-if="title" class="title">{{ title }}</h3>
    <component
      :is="componentIs"
      ref="form"
      :btnSubmitText="btnSubmitText"
      :data="data"
      :fieldSettings="fieldSettings"
      :widgetsIsDraggable="widgetsIsDraggable"
      :validation="validation"
      :layout="layout"
      :widgets="widgets"
      :tabs="tabs"
      :fields="fields"
      :agreementLink="agreementLink"
      @change="$emit('change', $event)"
      @onSave="$emit('onSave')"
      @onAttachmentDelete="$emit('onAttachmentDelete', $event)"
      @onAttachmentAppend="$emit('onAttachmentAppend', $event)"
    />
  </div>
</template>

<script>
import FormTabs from "@/components/Form/components/FormTabs.vue";
import FormSimple from "@/components/Form/components/FormSimple.vue";
import FormBoard from "@/components/Form/components/FormBoard.vue";

export default {
  components: { FormTabs, FormSimple, FormBoard },
  props: {
    title: {
      // Заголовок формы
      type: String,
      default: "",
    },
    btnSubmitText: {
      // текст кнопки
      type: String,
      default: "submit",
    },
    data: {
      // Рекативные данные полей
      type: Object,
      required: true,
    },
    fieldSettings: {
      // описание полей
      type: Object,
      required: true,
    },
    type: {
      // Тип формы
      type: String,
      default: "",
    },
    validation: {
      // Валидация полей
      type: Object,
      default: () => ({}),
    },
    layout: {
      // для типа формы - board
      type: Array,
      default: () => [],
    },
    widgets: {
      // для типа формы - board
      type: Array,
      default: () => [],
    },
    widgetsIsDraggable: {
      // для типа формы - board
      type: Boolean,
      default: false,
    },
    tabs: {
      // для типа формы - tabs
      type: Array,
      default: () => [],
    },
    fields: {
      // для типа формы - simple
      type: Array,
      default: () => [],
    },
    agreementLink: {
      // ссылка на пользовательское соглашение
      type: String,
      default: "",
    },
  },
  computed: {
    componentIs() {
      switch (this.type) {
        case "tabs":
          return "FormTabs";
        case "board":
          return "FormBoard";
        case "simple":
          return "FormSimple";
        default:
          return "";
      }
    },
  },
  methods: {
    getFormData() {
      return this.$refs.form.formData;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
}
</style>
