<template>
  <v-form ref="form">
    <template v-for="(fieldName, i) in fields">
      <h4 v-show="fieldSettings[fieldName].visible" :key="i" class="mb-2">
        {{ fieldSettings[fieldName].title }}
      </h4>
      <v-row
        v-show="fieldSettings[fieldName].visible"
        :key="'row' + i"
        no-gutters
        class="mb-2"
      >
        <v-col
          cols="12"
          sm="12"
          :md="fieldSettings[fieldName].grid"
          class="px-2"
        >
          <AppFieldCreator
            v-model.trim="formData[fieldName]"
            :label="fieldSettings[fieldName].title"
            :placeholder="fieldSettings[fieldName].placeholder"
            :items="fieldSettings[fieldName].options"
            :type="fieldSettings[fieldName].type"
            :itemText="fieldSettings[fieldName].optionText"
            :itemValue="fieldSettings[fieldName].optionValue"
            :prependIcon="fieldSettings[fieldName].icon"
            :fieldName="fieldName"
            :fieldSettings="fieldSettings[fieldName].children"
            :readonly="fieldSettings[fieldName].readonly"
            :v="$v.formData[fieldName]"
            class="px-2 mb-2"
            hide-details="auto"
            dense
            @input="$v.$touch()"
            @blur="$emit('blur', $event)"
            @change="$emit('change', $event)"
            @onAttachmentDelete="$emit('onAttachmentDelete', $event)"
            @onAttachmentAppend="$emit('onAttachmentAppend', $event)"
          />
        </v-col>
      </v-row>
    </template>

    <p v-if="agreementLink" class="subtitle-2">
      Нажимая на кнопку вы даете
      <a
        :href="agreementLink"
        target="_blank"
        class="text-caption primary--text"
        >согласие на обработку своих персональных данных</a
      >
    </p>

    <v-btn
      color="accent"
      elevation="1"
      class="mt-5"
      :disabled="formIsInvalid"
      @click="$emit('onSave')"
    >
      {{ btnSubmitText }}
    </v-btn>
  </v-form>
</template>

<script>
import formMixin from "@/components/Form/mixins/formMixin";

export default {
  mixins: [formMixin],
};
</script>
