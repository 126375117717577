<template>
  <v-form ref="form">
    <v-tabs v-if="tabs.length" v-model="selectedStep" right slider-size="1">
      <template v-for="(tab, i) in tabs">
        <v-tab :key="'tab' + i" :disabled="!tab.is_active">
          {{ tab.title }}
        </v-tab>
        <v-tab-item :key="'iabItem' + i">
          <template v-for="(fieldName, k) in tab.fields">
            <h4 v-show="fieldSettings[fieldName].visible" :key="k" class="mb-2">
              {{ fieldSettings[fieldName].title }}
            </h4>
            <v-row
              v-show="fieldSettings[fieldName].visible"
              :key="'row' + k"
              no-gutters
              class="mb-4"
            >
              <v-col cols="12" sm="12" :md="fieldSettings[fieldName].grid">
                <AppFieldCreator
                  v-model.trim="formData[fieldName]"
                  :label="fieldSettings[fieldName].title"
                  :placeholder="fieldSettings[fieldName].placeholder"
                  :items="fieldSettings[fieldName].options"
                  :type="fieldSettings[fieldName].type"
                  :itemText="fieldSettings[fieldName].optionText"
                  :itemValue="fieldSettings[fieldName].optionValue"
                  :prependIcon="fieldSettings[fieldName].icon"
                  :readonly="fieldSettings[fieldName].readonly"
                  :fieldName="fieldName"
                  :fieldSettings="fieldSettings[fieldName].children"
                  :v="$v.formData[fieldName]"
                  class="px-2 mb-2"
                  hide-details="auto"
                  dense
                  @input="$v.$touch()"
                  @blur="$emit('blur', $event)"
                  @change="$emit('change', $event)"
                  @onAttachmentDelete="$emit('onAttachmentDelete', $event)"
                  @onAttachmentAppend="$emit('onAttachmentAppend', $event)"
                />
              </v-col>
            </v-row>
          </template>
        </v-tab-item>
      </template>
    </v-tabs>

    <p v-if="agreementLink" class="subtitle-2">
      Нажимая на кнопку вы даете
      <a
        :href="agreementLink"
        target="_blank"
        class="text-caption primary--text"
        >согласие на обработку своих персональных данных</a
      >
    </p>

    <v-btn
      color="accent"
      elevation="1"
      class="mt-5"
      :disabled="formIsInvalid"
      @click="$emit('onSave')"
    >
      {{ btnSubmitText }}
    </v-btn>
  </v-form>
</template>

<script>
import formMixin from "@/components/Form/mixins/formMixin";

export default {
  mixins: [formMixin],

  data() {
    return {
      selectedStep: 0,
    };
  },
};
</script>
